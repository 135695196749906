var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.exercise !== undefined)?_c('div',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',[_vm._v(_vm._s(_vm.exercise.identifier))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.confirmDelete = true}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)],1),_c('v-divider'),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"exercise-row",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"link":"","depressed":"","to":{
            name: 'ReadingExerciseUpdate',
            params: { pk: _vm.exercise.pk }
          }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil-outline ")]),_vm._v(" Edit exercise ")],1),_c('div',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(_vm.exercise.content)}})],1),_c('v-col',{staticClass:"exercise-row",attrs:{"cols":"6"}},[(_vm.questions.length === 0)?_c('span',[_vm._v(" No answers yet. "),_c('router-link',{attrs:{"to":{
              name: 'ReadingExerciseEditAnswers',
              params: { pk: _vm.pk }
            }}},[_vm._v(" Add answers ")])],1):_c('div',[_c('v-btn',{attrs:{"link":"","depressed":"","to":{
              name: 'ReadingExerciseEditAnswers',
              params: { pk: _vm.exercise.pk }
            }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil-outline ")]),_vm._v(" Edit answers ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Passage 1")]),_vm._l((_vm.passage1Questions),function(question){return _c('v-list-item',{key:question.pk},[_c('v-list-item-content',[_vm._v(" "+_vm._s(question.number)+". "+_vm._s(_vm.formatAnswers(question.answers))+" ")])],1)})],2)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Passage 2")]),_vm._l((_vm.passage2Questions),function(question){return _c('v-list-item',{key:question.pk},[_c('v-list-item-content',[_vm._v(" "+_vm._s(question.number)+". "+_vm._s(_vm.formatAnswers(question.answers))+" ")])],1)})],2)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Passage 3")]),_vm._l((_vm.passage3Questions),function(question){return _c('v-list-item',{key:question.pk},[_c('v-list-item-content',[_vm._v(" "+_vm._s(question.number)+". "+_vm._s(_vm.formatAnswers(question.answers))+" ")])],1)})],2)],1)],1)],1)])],1),_c('KLDialogConfirm',{attrs:{"loading":_vm.deleting},on:{"confirm":_vm.deleteExercise,"cancel":function($event){_vm.confirmDelete = false}},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('p',[_vm._v(" You are deleting exercise "),_c('strong',[_vm._v(_vm._s(_vm.exercise.identifier))]),_vm._v(". ")]),_c('div',{staticClass:"error--text"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-outline ")]),_vm._v(" This cannot be undone! ")],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }