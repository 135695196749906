





































































import { User } from '@/interfaces/user'
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import KLAvatar from '@/components/KLAvatar.vue'
import KLDialogConfirm from '@/components/KLDialogConfirm.vue'
import { logout } from '@/utils/auth'

@Component({
  computed: {
    ...mapState('account', {
      user: 'loggedInUser'
    }),
    ...mapGetters('account', [
      'isTeacher'
    ])
  },
  components: {
    KLAvatar,
    KLDialogConfirm
  }
})
export default class MyInfo extends Vue {
  breadcrumbs = [
    { text: 'Home', to: { name: 'Home' }, exact: true },
    { text: 'Profile', to: { name: 'MyInfo' }, exact: true }
  ]

  user!: User
  isTeacher!: boolean

  get account (): string {
    return this.isTeacher ? 'Teacher' : 'Student'
  }

  logoutConfirm = false

  goodBye (): void {
    logout()
    this.$router.push({ name: 'Login' })
  }
}
