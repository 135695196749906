var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Students list ")]),_c('v-card-subtitle',[(_vm.students.length > 0)?_c('span',[_vm._v(" Click to see each student's tracking sheet ")]):_vm._e()]),_c('v-card-text',[(_vm.students.length === 0)?_c('span',[_vm._v(" This class doesn't have any students yet. "),_c('router-link',{attrs:{"to":{
          name: 'ClassroomStudents',
          params: { pk: _vm.classroom.pk },
          query: { adding: 'true' }
        }}},[_vm._v(" Add students ")])],1):_c('v-row',_vm._l(([_vm.studentsCol1, _vm.studentsCol2]),function(students,index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-list',_vm._l((students),function(student){return _c('v-list-item',{key:student.pk,attrs:{"link":"","to":{
              name: 'ClassroomOverviewStudentReport',
              params: {
                pk: _vm.classroom.pk,
                studentPk: student.pk
              }
            }}},[_c('v-list-item-avatar',[_c('KLAvatar',{attrs:{"user":student}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(student.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(student.email)+" ")])],1)],1)}),1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }