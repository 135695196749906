








































































































































































import { Api } from '@/api'
import { ReadingExerciseSubmitAnswersReq } from '@/interfaces/api/reading-exercise'
import { ReadingExercise } from '@/interfaces/reading-exercise'
import { ReadingAnswer, ReadingQuestion } from '@/interfaces/reading-question'
import { User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import KLDialogConfirm from '@/components/KLDialogConfirm.vue'
import KLTimer from '@/components/KLTimer.vue'
import { Classroom } from '@/interfaces/classroom'

@Component({
  computed: {
    ...mapState('readingExercise', {
      exercise: 'currentReadingExercise'
    }),
    ...mapState('readingExercise', {
      questions: 'currentQuestions'
    }),
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    }),
    ...mapState('account', {
      user: 'loggedInUser'
    })
  },
  components: {
    KLDialogConfirm,
    KLTimer
  }
})
export default class ReadingExerciseSubmit extends Vue {
  @Prop(Number) readonly pk!: number
  @Prop(Number) readonly exercisePk!: number

  async created (): Promise<void> {
    this.preventLeavePage()
    await this.setQuestions()
    this.setAnswers()
    this.setClassroom()
  }

  get breadcrumbs (): unknown[] {
    if (this.exercise === undefined) return []
    return [
      { text: 'Home', to: { name: 'Home' }, exact: true },
      { text: 'Classrooms', to: { name: 'ClassroomList' }, exact: true },
      { text: this.classroomName, to: { name: 'ClassroomExercisesReading', params: { pk: this.pk } }, exact: true },
      { text: this.exercise.identifier, disabled: true }
    ]
  }

  /**
   * Classroom
   */
  classroom!: Classroom

  setClassroom (): void {
    if (this.classroom !== undefined) {
      this.$store.dispatch('classroom/detail', this.pk)
    }
  }

  get classroomName (): string {
    if (this.classroom !== undefined) {
      return this.classroom.name
    } else {
      return 'Classroom'
    }
  }

  /**
   * Questions
   */
  exercise!: ReadingExercise
  questions!: ReadingQuestion[]
  loading = false

  async setQuestions (): Promise<void> {
    this.loading = true

    try {
      await this.$store.dispatch('readingExercise/detail', this.exercisePk)
      await this.$store.dispatch('readingExercise/getQuestions')
    } catch (error) {
      unexpectedExc(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * Answers
   */
  answers: ReadingAnswer[] = []
  currentPassage = '1'

  setAnswers (): void {
    this.answers = this.questions.map(question => {
      let choices: ReadingAnswer['choices'] = []

      if (question.question_type === 'true_false') {
        choices = [
          { text: 'TRUE', value: 'TRUE' },
          { text: 'FALSE', value: 'FALSE' },
          { text: 'NOT GIVEN', value: 'NOT_GIVEN' }
        ]
      } else if (question.question_type === 'yes_no') {
        choices = [
          { text: 'YES', value: 'YES' },
          { text: 'NO', value: 'NO' },
          { text: 'NOT GIVEN', value: 'NOT_GIVEN' }
        ]
      } else {
        choices = question.choices.map(choice => (
          { text: choice, value: choice }
        ))
      }

      return {
        question_number: question.number,
        content: '',
        question_type: question.question_type,
        choices,
        passage: question.passage
      }
    })
  }

  get currentPassageAnswers (): ReadingAnswer[] {
    return this.answers.filter(answer => answer.passage?.toString() === this.currentPassage)
  }

  get firstQuestionNum (): string {
    const firstQuestion = this.currentPassageAnswers[0]
    if (firstQuestion !== undefined) {
      return firstQuestion.question_number.toString()
    } else {
      return ''
    }
  }

  get lastQuestionNum (): string {
    const lastQuestion = this.currentPassageAnswers[this.currentPassageAnswers.length - 1]
    if (lastQuestion !== undefined) {
      return lastQuestion.question_number.toString()
    } else {
      return ''
    }
  }

  get answersCol1 (): ReadingAnswer[] {
    return this.currentPassageAnswers.slice(0, Math.ceil(this.currentPassageAnswers.length / 2))
  }

  get answersCol2 (): ReadingAnswer[] {
    return this.currentPassageAnswers.slice(Math.ceil(this.currentPassageAnswers.length / 2))
  }

  get allAnswered (): boolean {
    return this.answers.every(answer => answer.content !== '')
  }

  get passage1Completed (): boolean {
    return this.answers
      .filter(answer => answer.passage === 1)
      .every(answer => answer.content !== '')
  }

  get passage2Completed (): boolean {
    return this.answers
      .filter(answer => answer.passage === 2)
      .every(answer => answer.content !== '')
  }

  get passage3Completed (): boolean {
    return this.answers
      .filter(answer => answer.passage === 3)
      .every(answer => answer.content !== '')
  }

  /**
   * Submit
   */

  confirmSubmit = false
  loadingSubmit = false
  user!: User
  timeTaken = 0

  submitAnswers (): void {
    if (this.loadingSubmit) return
    this.loadingSubmit = true

    const answers: ReadingAnswer[] = this.answers
      .filter(answer => answer.content !== '')
      .map(answer => {
        delete answer.question_type
        delete answer.choices
        delete answer.passage
        return answer
      })

    const payload: ReadingExerciseSubmitAnswersReq = {
      time_taken: this.timeTaken,
      answers
    }

    Api.readingExercise.submitAnswers(this.exercise.pk, payload)
      .catch(unexpectedExc)
      .finally(() => {
        this.confirmSubmit = false
        this.loadingSubmit = false
        this.$router.push({
          name: this.submitRouteName,
          params: {
            pk: this.pk.toString(),
            exercisePk: this.exercisePk.toString(),
            studentPk: this.user.pk.toString()
          }
        })
      })
  }

  /**
   * Make sure student don't accidentally leave route when they haven't finished
   */
  confirmLeave = false
  nextRoute: CallableFunction | null = null
  submitRouteName = 'ReadingExerciseSubmitResult'

  goNextRoute (): void {
    this.allowLeavePage()
    if (this.nextRoute !== null) {
      this.nextRoute()
    }
  }

  // @ts-expect-error: don't care
  // eslint-disable-next-line
  beforeRouteLeave (to, from, next): void {
    if (
      !this.confirmLeave &&
      to.name !== this.submitRouteName
    ) {
      this.confirmLeave = true
      this.nextRoute = next
    } else {
      this.allowLeavePage()
      next()
    }
  }

  preventLeavePage (): void {
    window.onbeforeunload = () => ''
  }

  allowLeavePage (): void {
    window.onbeforeunload = () => undefined
  }
}
