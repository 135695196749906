

































import { User } from '@/interfaces/user'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      loggedInUser: 'account/loggedInUser'
    })
  }
})
export default class KLAvatar extends Vue {
  @Prop() readonly user!: User | undefined
  @Prop({ type: String, default: 'secondary' }) readonly backgroundColor!: string

  loggedInUser!: User

  get localUser (): User {
    return this.user || this.loggedInUser
  }

  get imgSize (): number {
    return parseInt(this.$attrs.size || '48')
  }

  get imgSrc (): string | null {
    if (this.imgSize > 64) {
      return this.localUser.avatar
    } else {
      return this.localUser.avatar_thumbnail
    }
  }

  get fontSize (): string {
    const imgSize = this.imgSize * 0.5
    return imgSize.toString() + 'px'
  }
}
