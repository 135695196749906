























































































































import { Classroom } from '@/interfaces/classroom'
import { unexpectedExc } from '@/utils'
import { Vue, Component } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'
import LayoutDefault from './LayoutDefault.vue'
import KLDialogConfirm from '@/components/KLDialogConfirm.vue'

@Component({
  components: {
    LayoutDefault,
    KLDialogConfirm
  },
  computed: {
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    })
  },
  methods: {
    ...mapMutations('classroom', {
      setCurrentClassroom: 'SET_CURRENT_CLASSROOM'
    }),
    ...mapMutations('message', {
      showMessage: 'SHOW_MESSAGE'
    })
  }
})
export default class LayoutClassroomTeacher extends Vue {
  // eslint-disable-next-line no-undef
  [key: string]: unknown

  classroom!: Classroom
  setCurrentClassroom!: CallableFunction
  showMessage!: CallableFunction

  get breadcrumbs (): unknown[] {
    if (this.classroom === undefined) return []
    return [
      { text: 'Home', to: { name: 'Home' }, exact: true },
      { text: 'Classrooms', to: { name: 'ClassroomList' }, exact: true },
      { text: this.classroom.name, disabled: true }
    ]
  }

  get pk (): string {
    return this.$route.params.pk
  }

  get loading (): boolean {
    return this.classroom === undefined
  }

  created (): void {
    this.setClassroom()
  }

  classroomFound = false

  setClassroom (): void {
    this.$store.dispatch('classroom/detail', this.pk)
      .then(() => {
        this.classroomFound = true
      })
      .catch(unexpectedExc)
  }

  tabs = [
    { text: 'Overview', to: { name: 'ClassroomOverview' } },
    { text: 'Students', to: { name: 'ClassroomStudents' } },
    { text: 'Reading exercises', to: { name: 'ClassroomExercisesReading' } },
    { text: 'Listening exercises', to: { name: 'ClassroomExercisesListening' } }
  ]

  /**
   * Delete classroom
   */
  confirmDelete = false
  deleting = false

  deleteClassroom (): void {
    if (this.deleting) return
    this.deleting = true

    this.$store.dispatch('classroom/delete', this.classroom.pk)
      .then(() => {
        this.$router.push({ name: 'ClassroomList' })
        this.showMessage('Classroom deleted.')
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.deleting = false
      })
  }

  // @ts-expect-error: don't care
  // eslint-disable-next-line
  beforeRouteLeave (to, from, next): void {
    this.setCurrentClassroom(undefined)
    next()
  }
}
