








































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('account', [
      'isTeacher',
      'isStudent'
    ])
  }
})
export default class Home extends Vue {
  breadcrumbs = [
    { text: 'Home', to: { name: 'Home' } }
  ]

  isTeacher!: boolean
  isStudent!: boolean

  // eslint-disable-next-line
  get links () {
    const classroomTeacherDescription = 'Create classrooms, add students, track their progress.'
    const classroomStudentDescription = 'Join classrooms, practice exercises and get instant feedback.'

    const links = [
      {
        text: 'Classrooms',
        to: { name: 'ClassroomList' },
        description: this.isTeacher ? classroomTeacherDescription : classroomStudentDescription
      }
    ]

    if (this.isTeacher) {
      links.push(
        {
          text: 'Reading exercises',
          to: { name: 'ReadingExerciseList' },
          description: 'Create reading exercises for using in your classrooms.'
        }
      )
    }

    links.push(...[
      {
        text: 'Profile',
        to: { name: 'MyInfo' },
        description: 'Manage your profile.'
      }
    ])
    return links
  }
}
