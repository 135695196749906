var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutDefault',[_c('v-container',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.classroom !== undefined)?_c('div',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{domProps:{"textContent":_vm._s(_vm.classroom.name)}})]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-x":"","left":"","nudge-left":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":{
                  name: 'ClassroomUpdate',
                  params: { pk: _vm.classroom.pk }
                }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-3"},[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.confirmDelete = true}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-3"},[_vm._v("Delete")])],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('p',{staticClass:"mt-5 description",domProps:{"textContent":_vm._s(_vm.classroom.description)}}),(_vm.classroom.reading_exercises.length === 0)?_c('div',{staticClass:"my-5 error--text"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"error"}},[_vm._v(" mdi-alert-outline ")]),_vm._v(" You haven't added any reading exercises to this classroom yet. "),_c('router-link',{attrs:{"to":{
            name: 'ClassroomExercisesReading',
            params: { pk: _vm.classroom.pk }
          }}},[_vm._v(" Add exercises ")])],1):_vm._e(),_c('v-tabs',_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.text,attrs:{"link":"","to":tab.to},domProps:{"textContent":_vm._s(tab.text)}})}),1),(_vm.classroomFound)?_c('router-view',{staticClass:"mt-5"}):_vm._e(),_c('KLDialogConfirm',{attrs:{"loading":_vm.deleting},on:{"confirm":_vm.deleteClassroom,"cancel":function($event){_vm.confirmDelete = false}},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('div',[_c('p',[_vm._v(" You are deleting classroom "),_c('strong',[_vm._v(_vm._s(_vm.classroom.name))]),_vm._v(". ")]),_c('div',{staticClass:"error--text"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-outline ")]),_vm._v(" This cannot be undone! ")],1)])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }