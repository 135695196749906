




















































































































import { Api } from '@/api'
import { GetStudentReportReq } from '@/interfaces/api/classroom'
import { Classroom, ReadingExerciseReport } from '@/interfaces/classroom'
import { User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    })
  }
})
export default class ClassroomOverviewStudentReport extends Vue {
  @Prop(Number) readonly pk!: number
  @Prop(Number) readonly studentPk!: number

  classroom!: Classroom
  reports: ReadingExerciseReport[] = []
  student: User | null = null
  loading = false

  created (): void {
    this.setReports()
    this.setStudent()
  }

  setReports (): void {
    this.loading = true

    const params: GetStudentReportReq = {
      student_pk: this.studentPk,
      show_detail: false
    }

    Api.classroom.getStudentReport(this.classroom.pk, params)
      .then(data => {
        this.reports = data
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  setStudent (): void {
    Api.account.userDetail(this.studentPk)
      .then(data => {
        this.student = data
      })
      .catch(unexpectedExc)
  }
}
