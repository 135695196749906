









import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Http404 extends Vue {

}
