
























































































import { PaginationQuery } from '@/interfaces/api/common'
import { Classroom } from '@/interfaces/classroom'
import { unexpectedExc } from '@/utils'
import { Mixins, Component, Watch } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { mapGetters, mapState } from 'vuex'
import { PaginationMixin } from '@/mixins/pagination-mixin'

@Component({
  computed: {
    ...mapState('classroom', [
      'classrooms',
      'pagination'
    ]),
    ...mapGetters('account', [
      'isTeacher'
    ])
  }
})
export default class ClassroomList extends Mixins(PaginationMixin) {
  // eslint-disable-next-line no-undef
  [key: string]: unknown

  breadcrumbs = [
    { text: 'Home', to: { name: 'Home' }, exact: true },
    { text: 'Classrooms', to: { name: 'ClassroomList' }, exact: true }
  ]

  /**
   * Init
   */
  isTeacher!: boolean
  classrooms!: Classroom[]
  localClassrooms: Classroom[] = []

  get noClassrooms (): boolean {
    return this.classrooms.length === 0
  }

  get classroomsCol1 (): Classroom[] {
    return this.localClassrooms.slice(0, Math.ceil(this.localClassrooms.length / 2))
  }

  get classroomsCol2 (): Classroom[] {
    return this.localClassrooms.slice(Math.ceil(this.localClassrooms.length / 2))
  }

  created (): void {
    this.getList()
  }

  getClassroomLink (classroom: Classroom): Location {
    if (this.isTeacher) {
      return {
        name: 'ClassroomOverview',
        params: {
          pk: classroom.pk.toString()
        }
      }
    } else {
      return {
        name: 'ClassroomExercisesReading',
        params: {
          pk: classroom.pk.toString()
        }
      }
    }
  }

  /**
   * List
   */
  loading = true

  getList (query?: PaginationQuery): void {
    this.loading = true

    this.$store.dispatch('classroom/list', query)
      .then(() => {
        this.localClassrooms = this.classrooms
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  /**
   * Search
   */
  searchText = ''

  @Watch('searchText')
  onSearch (text: string): void {
    text = text.toLowerCase()
    this.localClassrooms = this.classrooms.filter(
      classroom => classroom.name.toLowerCase().indexOf(text) !== -1
    )
  }
}
