

































































































import { PaginationQuery } from '@/interfaces/api/common'
import { ReadingExercise } from '@/interfaces/reading-exercise'
import { PaginationMixin } from '@/mixins/pagination-mixin'
import { unexpectedExc } from '@/utils'
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('readingExercise', {
      exercises: 'readingExercises',
      pagination: 'exercisePagination'
    })
  }
})
export default class ReadingExerciseList extends Mixins(PaginationMixin) {
  // eslint-disable-next-line no-undef
  [key: string]: unknown

  breadcrumbs = [
    { text: 'Home', to: { name: 'Home' }, exact: true },
    { text: 'Reading exercises', to: { name: 'ReadingExerciseList' }, exact: true }
  ]

  /**
   * Init exercises
   */
  exercises!: ReadingExercise[]
  localExercises: ReadingExercise[] = []

  get noExercises (): boolean {
    return this.exercises.length === 0
  }

  created (): void {
    this.getList()
  }

  get exercisesCol1 (): ReadingExercise[] {
    return this.localExercises.slice(0, Math.ceil(this.localExercises.length / 2))
  }

  get exercisesCol2 (): ReadingExercise[] {
    return this.localExercises.slice(Math.ceil(this.localExercises.length / 2))
  }

  /**
   * List
   */
  loading = false

  getList (query?: PaginationQuery): void {
    this.loading = true

    this.$store.dispatch('readingExercise/list', query)
      .then(() => {
        this.localExercises = this.exercises
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  /**
   * Create
   */
  goToNewExercise (): void {
    this.$router.push({ name: 'ReadingExerciseCreate' })
  }

  /**
   * Search
   */
  searchText = ''

  @Watch('searchText')
  onSearch (text: string): void {
    text = text.toLowerCase()
    this.localExercises = this.exercises.filter(
      ex => ex.identifier.toLowerCase().indexOf(text) !== -1
    )
  }
}
