


















































































































































import { ReadingQuestion } from '@/interfaces/reading-question'
import { Mixins, Component } from 'vue-property-decorator'
import { ReadingExerciseMixin } from '@/mixins/reading-exercise-mixin'
import { unexpectedExc } from '@/utils'
import KLDialogConfirm from '@/components/KLDialogConfirm.vue'
import { mapMutations } from 'vuex'

@Component({
  methods: {
    ...mapMutations('message', {
      showMessage: 'SHOW_MESSAGE'
    })
  },
  components: {
    KLDialogConfirm
  }
})
export default class ReadingExerciseDetail extends Mixins(ReadingExerciseMixin) {
  get breadcrumbs (): unknown[] {
    if (this.exercise === undefined) return []
    return [
      { text: 'Home', to: { name: 'Home' }, exact: true },
      { text: 'Reading exercises', to: { name: 'ReadingExerciseList' }, exact: true },
      { text: this.exercise.identifier, disabled: true }
    ]
  }

  get passage1Questions (): ReadingQuestion[] {
    return this.questions.filter(question => question.passage === 1)
  }

  get passage2Questions (): ReadingQuestion[] {
    return this.questions.filter(question => question.passage === 2)
  }

  get passage3Questions (): ReadingQuestion[] {
    return this.questions.filter(question => question.passage === 3)
  }

  formatAnswers (answers: ReadingQuestion['answers']): string {
    return answers.join(' / ')
  }

  /**
   * Delete exercise
   */
  confirmDelete = false
  deleting = false
  showMessage!: CallableFunction

  deleteExercise (): void {
    if (this.deleting) return
    this.deleting = true

    this.$store.dispatch('readingExercise/delete', this.exercise.pk)
      .then(() => {
        this.showMessage('Exercise deleted.')
        this.$router.push({ name: 'ReadingExerciseList' })
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.deleting = false
      })
  }
}
