





























































import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Classroom } from '@/interfaces/classroom'
import KLAvatar from '@/components/KLAvatar.vue'

@Component({
  computed: {
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    })
  },
  components: {
    KLAvatar
  }
})
export default class ClassroomOverview extends Vue {
  classroom!: Classroom

  get students (): Classroom['students'] {
    return this.classroom.students
  }

  get studentsCol1 (): Classroom['students'] {
    return this.students.slice(0, Math.ceil(this.students.length / 2))
  }

  get studentsCol2 (): Classroom['students'] {
    return this.students.slice(Math.ceil(this.students.length / 2))
  }
}
