import { render, staticRenderFns } from "./LayoutClassroomStudent.vue?vue&type=template&id=218afdec&scoped=true&"
import script from "./LayoutClassroomStudent.vue?vue&type=script&lang=ts&"
export * from "./LayoutClassroomStudent.vue?vue&type=script&lang=ts&"
import style0 from "./LayoutClassroomStudent.vue?vue&type=style&index=0&id=218afdec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218afdec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBreadcrumbs,VContainer,VDivider,VProgressCircular,VTab,VTabs})
