





























































































































































import { Api } from '@/api'
import { Classroom, ReadingExerciseReport } from '@/interfaces/classroom'
import { ReadingExercise } from '@/interfaces/reading-exercise'
import { User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { mapGetters, mapState } from 'vuex'
import KLAvatar from '@/components/KLAvatar.vue'

@Component({
  computed: {
    ...mapState('readingExercise', {
      exercise: 'currentReadingExercise'
    }),
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    }),
    ...mapGetters('account', [
      'isTeacher'
    ])
  },
  components: {
    KLAvatar
  }
})
export default class ReadingExerciseSubmitResult extends Vue {
  @Prop(Number) readonly pk!: number
  @Prop(Number) readonly exercisePk!: number
  @Prop(Number) readonly studentPk!: number

  get breadcrumbs (): unknown[] {
    if (this.exercise === undefined) return []

    let classroomLink
    if (this.isTeacher) {
      classroomLink = {
        text: this.classroomName,
        to: {
          name: 'ClassroomOverviewStudentReport',
          params: {
            pk: this.pk,
            studentPk: this.studentPk
          }
        },
        exact: true
      }
    } else {
      classroomLink = {
        text: this.classroomName,
        to: {
          name: 'ClassroomExercisesReading',
          params: { pk: this.pk }
        },
        exact: true
      }
    }

    return [
      { text: 'Home', to: { name: 'Home' }, exact: true },
      { text: 'Classrooms', to: { name: 'ClassroomList' }, exact: true },
      classroomLink,
      { text: this.exercise.identifier, disabled: true }
    ]
  }

  /**
   * Init
   */
  loading = false
  isTeacher!: boolean

  async created (): Promise<void> {
    this.loading = true

    try {
      this.setStudent()

      await this.setExercise()
      const reports = await this.setReport()
      if (reports.length > 0) {
        this.report = reports[0]
      }
    } catch (error) {
      unexpectedExc(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * Classroom
   */
  classroom!: Classroom

  setClassroom (): void {
    if (this.classroom !== undefined) {
      this.$store.dispatch('classroom/detail', this.pk)
    }
  }

  get classroomName (): string {
    if (this.classroom !== undefined) {
      return this.classroom.name
    } else {
      return 'Classroom'
    }
  }

  /**
   * Exercise
   */
  exercise!: ReadingExercise

  setExercise (): Promise<unknown> {
    return this.$store.dispatch('readingExercise/detail', this.exercisePk)
  }

  /**
   * Report
   */
  report: ReadingExerciseReport | null = null

  setReport (): Promise<ReadingExerciseReport[]> {
    return Api.classroom.getStudentReport(this.pk, {
      student_pk: this.studentPk,
      exercise_pk: this.exercisePk,
      show_detail: true
    })
  }

  /**
   * Student
   */
  student: User | null = null

  setStudent (): void {
    Api.account.userDetail(this.studentPk)
      .then(data => {
        this.student = data
      })
      .catch(unexpectedExc)
  }

  /**
   * Utils
   */
  formatAnswer (answer: string): string {
    if (answer === 'NOT_GIVEN') {
      answer = 'NOT GIVEN'
    }
    return answer
  }

  formatCorrectAnswer (answers: string[]): string {
    answers = answers.map(answer => {
      if (answer === 'NOT_GIVEN') {
        answer = 'NOT GIVEN'
      }
      return answer
    })
    return answers.join(' / ')
  }

  formatDatetime (dt: string): string {
    const datetime = new Date(dt)
    return datetime.toLocaleString()
  }

  get goBackLink (): Location {
    if (this.isTeacher) {
      return {
        name: 'ClassroomOverviewStudentReport',
        params: {
          pk: this.pk.toString(),
          studentPk: this.studentPk.toString()
        }
      }
    } else {
      return {
        name: 'ClassroomExercisesReading',
        params: {
          pk: this.pk.toString()
        }
      }
    }
  }
}
