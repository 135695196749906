
































































import { Vue, Component, Emit } from 'vue-property-decorator'
import { LoginReq } from '@/interfaces/api/account'
import { snakeCaseToCamelCase, unexpectedExc } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { mapState } from 'vuex'
import BaseAuth from './BaseAuth.vue'

@Component({
  computed: {
    ...mapState('account', [
      'isTeacher'
    ])
  },
  components: {
    BaseAuth
  }
})
export default class Login extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  email = ''
  password = ''
  emailErrs: string[] = []
  passwordErrs: string[] = []
  showPassword = false
  loading = false
  errorMsg = ''
  isTeacher!: boolean

  async login (): Promise<void> {
    this.errorMsg = ''
    if (this.loading) return
    this.loading = true

    const payload: LoginReq = {
      email: this.email,
      password: this.password
    }

    try {
      await this.$store.dispatch('account/login', payload)
      await this.$store.dispatch('account/getInfo')
      // TODO: implement ?next
      if (this.isTeacher) {
        this.$router.push({ name: 'Home' })
      } else {
        this.$router.push({ name: 'ClassroomList' })
      }
    } catch (error) {
      if (assertErrCode(error, status.HTTP_401_UNAUTHORIZED)) {
        this.errorMsg = error.response.data.detail
      } else if (assertErrCode(error, status.HTTP_400_BAD_REQUEST)) {
        const data = error.response.data
        Object.entries(data).forEach(([field, errMsgs]) => {
          const attr = `${snakeCaseToCamelCase(field)}Errs`
          this[attr] = errMsgs
        })
      } else {
        unexpectedExc(error)
      }
    } finally {
      this.loading = false
    }
  }

  @Emit('change-page')
  changePage (e: Event): Event { return e }
}
