var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.showAddStudent = !_vm.showAddStudent}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.showAddStudent ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline')+" ")]),_vm._v(" Add student ")],1),(_vm.showAddStudent)?_c('div',{staticClass:"mt-3"},[_c('v-card',{attrs:{"width":"50%"}},[_c('v-card-subtitle',[_vm._v(" An email containing temporary password will be sent to your student after adding. Tell them to login with that password. ")]),_c('v-card-text',[_c('v-form',{ref:"addStudentForm",nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addStudent.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Name *","autofocus":_vm.showAddStudent,"error-messages":_vm.nameErrs,"error-count":_vm.nameErrs.length},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"Email *","error-messages":_vm.emailErrs,"error-count":_vm.emailErrs.length},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Phone number","error-messages":_vm.phoneNumberErrs,"error-count":_vm.phoneNumberErrs.length},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingAddStudent,"min-width":"110"},on:{"click":_vm.addStudent}},[_vm._v(" Add ")])],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"mt-3 elevation-2",attrs:{"headers":_vm.headers,"items":_vm.students,"items-per-page":-1,"no-data-text":"This class doesn't have any students yet."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('KLAvatar',{attrs:{"user":item,"size":"32"}}),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(item.name))])]}},{key:"item.manage",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":"","left":"","nudge-left":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":item.last_login !== null},on:{"click":function($event){return _vm.resendPasswordEmail(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-email-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Resend password email ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.prepareRemoveStudent(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-account-remove-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Remove from classroom ")])],1)],1)],1)],1)]}}])}),_c('KLDialogConfirm',{attrs:{"loading":_vm.loadingRemove},on:{"confirm":_vm.removeStudent,"cancel":function($event){_vm.removeConfirm = false}},model:{value:(_vm.removeConfirm),callback:function ($$v) {_vm.removeConfirm=$$v},expression:"removeConfirm"}},[(_vm.studentToRemove !== null)?_c('div',[_vm._v(" You are removing "),_c('b',[_vm._v(_vm._s(_vm.studentToRemove.name)+" ("+_vm._s(_vm.studentToRemove.email)+")")]),_vm._v(" from this classroom. ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }