




































































import { User } from '@/interfaces/user'
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import KLAvatar from '@/components/KLAvatar.vue'

@Component({
  computed: {
    ...mapGetters({
      user: 'account/loggedInUser'
    })
  },
  components: {
    KLAvatar
  }
})
export default class LayoutDefault extends Vue {
  user!: User
}
