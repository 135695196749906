










































































































































import { AddStudentReq, RemoveStudentReq } from '@/interfaces/api/classroom'
import { Classroom } from '@/interfaces/classroom'
import { User } from '@/interfaces/user'
import { VForm } from '@/interfaces/vuetify'
import { snakeCaseToCamelCase, unexpectedExc } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { Vue, Component } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'
import KLDialogConfirm from '@/components/KLDialogConfirm.vue'
import KLAvatar from '@/components/KLAvatar.vue'
import { Api } from '@/api'

@Component({
  computed: {
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    })
  },
  methods: {
    ...mapMutations('message', {
      showMessage: 'SHOW_MESSAGE'
    })
  },
  components: {
    KLDialogConfirm,
    KLAvatar
  }
})
export default class ClassroomStudents extends Vue {
  // eslint-disable-next-line no-undef
  [key: string]: unknown

  classroom!: Classroom

  get students (): Classroom['students'] {
    if (this.classroom !== undefined) {
      return this.classroom.students
    }
    return []
  }

  mounted (): void {
    const adding = this.$route.query.adding
    if (adding === 'true') {
      this.showAddStudent = true
    }
  }

  /**
   * Students table
   */
  headers = [
    { text: 'Name', value: 'name' },
    { text: 'Email', value: 'email' },
    { text: 'Phone number', value: 'phone_number', sortable: false },
    { text: '', value: 'manage', sortable: false }
  ]

  /**
   * Add student
   */
  showAddStudent = false
  loadingAddStudent = false
  showMessage!: CallableFunction

  name = ''
  email = ''
  phoneNumber = ''

  nameErrs = []
  emailErrs = []
  phoneNumberErrs = []

  addStudent (): void {
    if (this.loadingAddStudent) return
    this.loadingAddStudent = true

    const payload: AddStudentReq = {
      name: this.name,
      email: this.email,
      phone_number: this.phoneNumber
    }
    this.$store.dispatch('classroom/addStudents', [payload])
      .then(() => {
        (this.$refs.addStudentForm as VForm).reset()
        this.showMessage('Student added.')
      })
      .catch(err => {
        if (assertErrCode(err, status.HTTP_400_BAD_REQUEST)) {
          const data = err.response.data
          if (data.length === 0) return

          Object.entries(data[0]).forEach(([field, errMsgs]) => {
            field = snakeCaseToCamelCase(field)
            const attr = `${field}Errs`
            this[attr] = errMsgs
          })
        } else {
          unexpectedExc(err)
        }
      })
      .finally(() => {
        this.loadingAddStudent = false
      })
  }

  /**
   * Remove student
   */
  removeConfirm = false
  studentToRemove: User | null = null
  loadingRemove = false

  prepareRemoveStudent (item: User): void {
    this.studentToRemove = item
    this.removeConfirm = true
  }

  removeStudent (): void {
    if (this.loadingRemove || this.studentToRemove === null) return
    this.loadingRemove = true

    const payload: RemoveStudentReq = {
      email: this.studentToRemove.email
    }

    this.$store.dispatch('classroom/removeStudents', [payload])
      .then(() => {
        this.removeConfirm = false
        this.showMessage('Student removed.')
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loadingRemove = false
      })
  }

  /**
   * Resend password email
   */
  resendPasswordEmail (student: User): void {
    Api.classroom.resendPasswordEmail(this.classroom.pk, {
      email: student.email
    })
      .then(() => {
        this.showMessage('Email sent.')
      })
      .catch(unexpectedExc)
  }
}
