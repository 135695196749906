




























































import { Api } from '@/api'
import { snakeCaseToCamelCase, unexpectedExc } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { Vue, Component } from 'vue-property-decorator'
import BaseAuth from './BaseAuth.vue'

@Component({
  components: {
    BaseAuth
  }
})
export default class ResetPassword extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  email = ''
  emailErrs: string[] = []
  loading = false
  sent = false

  submit (): void {
    if (this.loading) return
    this.loading = true

    Api.account.emailResetPasswordLink({
      email: this.email
    })
      .then(() => {
        this.sent = true
      })
      .catch(err => {
        if (assertErrCode(err, status.HTTP_400_BAD_REQUEST)) {
          const data = err.response.data
          Object.entries(data).forEach(([field, errMsgs]) => {
            const attr = `${snakeCaseToCamelCase(field)}Errs`
            this[attr] = errMsgs
          })
        } else {
          unexpectedExc(err)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
