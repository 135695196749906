















































































import { Api } from '@/api'
import { GetStudentReportReq } from '@/interfaces/api/classroom'
import { Classroom, ReadingExerciseReport } from '@/interfaces/classroom'
import { User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('account', {
      user: 'loggedInUser'
    }),
    ...mapState('classroom', {
      classroom: 'currentClassroom'
    })
  }
})
export default class ClassroomExercisesReadingStudent extends Vue {
  user!: User
  classroom!: Classroom
  reports: ReadingExerciseReport[] = []

  loading = false

  created (): void {
    this.loading = true

    const params: GetStudentReportReq = {
      student_pk: this.user.pk,
      show_detail: false
    }

    Api.classroom.getStudentReport(this.classroom.pk, params)
      .then(data => {
        this.reports = data
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }
}
