var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.reports.length === 0)?_c('p',[_vm._v(" This classroom don't have any reading exercises. Perhaps your teacher forgot to add them? ")]):_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Reading")]),_c('th',[_vm._v("Passage 1")]),_c('th',[_vm._v("Passage 2")]),_c('th',[_vm._v("Passage 3")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Band score")]),_c('th')])]),_c('tbody',_vm._l((_vm.reports),function(report){return _c('tr',{key:report.pk},[_c('td',[_vm._v(_vm._s(report.exercise.identifier))]),(!report.submitted)?[_c('td',{attrs:{"colspan":"5"}},[_vm._v(" Not submitted ")]),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
                    name: 'ReadingExerciseSubmit',
                    params: {
                      pk: _vm.classroom.pk,
                      exercisePk: report.exercise.pk
                    }
                  }}},[_vm._v(" Start ")])],1)]:[_c('td',[_vm._v(_vm._s(report.passage_1_total))]),_c('td',[_vm._v(_vm._s(report.passage_2_total))]),_c('td',[_vm._v(_vm._s(report.passage_3_total))]),_c('td',[_vm._v(_vm._s(report.total))]),_c('td',[_vm._v(_vm._s(report.band_score))]),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
                    name: 'ReadingExerciseSubmitResult',
                    params: {
                      pk: _vm.classroom.pk,
                      exercisePk: report.exercise.pk,
                      studentPk: _vm.user.pk
                    }
                  }}},[_vm._v(" Detail ")])],1)]],2)}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }