var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.reports.length === 0)?_c('p',[_vm._v(" No reports for this student. ")]):_c('div',[_c('v-card',[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$router.push({
              name: 'ClassroomOverview',
              params: { pk: _vm.classroom.pk }
            })}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-title',[(_vm.student !== null)?_c('span',[_vm._v(_vm._s(_vm.student.name))]):_vm._e()]),_c('v-card-subtitle',[(_vm.student !== null)?_c('span',[_vm._v(_vm._s(_vm.student.email))]):_vm._e()])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Reading")]),_c('th',[_vm._v("Passage 1")]),_c('th',[_vm._v("Passage 2")]),_c('th',[_vm._v("Passage 3")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Band score")])])]),_c('tbody',_vm._l((_vm.reports),function(report){return _c('tr',{key:report.pk,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                    name: 'ReadingExerciseSubmitResult',
                    params: {
                      pk: _vm.classroom.pk,
                      exercisePk: report.exercise.pk,
                      studentPk: _vm.studentPk
                    }
                  })}}},[_c('td',[_vm._v(_vm._s(report.exercise.identifier))]),(!report.submitted)?[_c('td',{attrs:{"colspan":"6"}},[_vm._v(" Not submitted ")])]:[_c('td',[_vm._v(_vm._s(report.passage_1_total))]),_c('td',[_vm._v(_vm._s(report.passage_2_total))]),_c('td',[_vm._v(_vm._s(report.passage_3_total))]),_c('td',[_vm._v(_vm._s(report.total))]),_c('td',[_vm._v(_vm._s(report.band_score))])]],2)}),0)])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Listening")]),_c('th',[_vm._v("Section 1")]),_c('th',[_vm._v("Section 2")]),_c('th',[_vm._v("Section 3")]),_c('th',[_vm._v("Section 4")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Band score")])])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Coming soon")])])])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }