


















































import { unexpectedExc } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { Vue, Component, Emit } from 'vue-property-decorator'
import BaseAuth from './BaseAuth.vue'

@Component({
  components: {
    BaseAuth
  }
})
export default class Register extends Vue {
  // eslint-disable-next-line no-undef
  [key: string]: unknown

  @Emit('change-page')
  changePage (e: Event): Event { return e }

  email = ''
  password = ''
  showPassword = false
  loading = false

  emailErrs: string[] = []
  passwordErrs: string[] = []

  register (): void {
    if (this.loading) return
    this.loading = true
    this.resetValidation()

    const payload = {
      email: this.email,
      password: this.password
    }

    this.$store.dispatch('account/registerTeacher', payload)
      .then(() => {
        return this.$store.dispatch('account/login', payload)
      })
      .then(() => {
        this.$router.push({ name: 'Home' })
      })
      .catch(error => {
        if (assertErrCode(error, status.HTTP_400_BAD_REQUEST)) {
          const data = error.response.data
          Object.entries(data).forEach(([field, errMsgs]) => {
            const attr = `${field}Errs`
            this[attr] = errMsgs
          })
        } else {
          unexpectedExc(error)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  resetValidation (): void {
    this.emailErrs = []
    this.passwordErrs = []
  }
}
